import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";

export const PaymentBlock = () => {
  const [id, setId] = useState("");
  const history = useNavigate();

  useEffect(() => {
    const res = localStorage.getItem("user_id");
    if (res) {
      setId(res);
    }
  }, []);

  return (
    <div className="payment_block">
      <p>Для выполнения упражнений необходима оплата.</p>
      <p>Стоимость 2 900 р.</p>
      <button className="button" onClick={() => history("/payment/" + id)}>
        Перейти к оплате
      </button>
    </div>
  );
};

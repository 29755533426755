import { FC, useState } from "react";
import { useNavigate } from "react-router";
import TextareaAutosize from "react-textarea-autosize";

const ITEM_TYPES: { [type: string]: string } = {
  exercise: "Следующее упражнение",
  level: "Следующий блок",
};

interface ExerciseResultProps {
  next_type: string;
  next_item: string;
  type: string;
}

export const ExerciseResult: FC<ExerciseResultProps> = ({ next_type, next_item, type }) => {
  const [text, setText] = useState("");

  const history = useNavigate();

  //   const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
  //     if (event.key === "Enter") {
  //       event.preventDefault();
  //       if (text.length > 1) {
  //         mutation.mutate({ text, task_id: data.task.id, exercise_id: data.id });
  //       }
  //     }
  //   };

  const clickNextButton = () => {
    if (next_type === "exercise") {
      history("/exercise/" + next_item);
    } else if (next_type === "level") {
      history("/levels/" + next_item);
    }
  };

  if (type === "not_current") {
    return (
      <div>
        <h2>Пройди прошлое упражнение</h2>
        <div className="mb-10">Проходи упражнения последовательно. Это важно.</div>
        <button className="button" onClick={clickNextButton}>
          Перейти
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <h2>Упражнение окончено!</h2>
        <button className="button" onClick={clickNextButton}>
          {ITEM_TYPES[next_type]}
        </button>

        {/* <TextareaAutosize
          className="textarea"
          value={text}
          onKeyDown={handleKeyPress}
          onChange={(e) => setText(e.target.value)}
        /> */}
      </div>
    );
  }
};

import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { API } from "../../config/api";
import { addInfoToLocalStorage } from "../../utils/localStorage";

// const addInfoToLocalStorage = (data: any) => {
//   localStorage.setItem("token", data.token);
//   //   localStorage.setItem("email", data.email);
//   //   localStorage.setItem("user_id", data.user_id);
// };

const EMAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

export const Login = () => {
  const [email, setEmail] = useState("");
  const [type, setType] = useState<"registration" | "login">("registration");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const history = useNavigate();

  const clickHandler = async () => {
    const isEmail = EMAIL_REGEXP.test(email);
    if (isEmail) {
      setIsLoading(true);
      const data =
        type === "registration"
          ? await API.client.login({ email })
          : await API.client.registration({ email, password });
      if (data) {
        if (data?.action === "login") {
          setType("login");
        } else if (data?.action === "error") {
          setError(data.error);
        } else {
          addInfoToLocalStorage(data);
          history("/levels");
        }
      }
    } else {
      setError("Введи корректный email");
    }
  };

  const changeType = () => {
    if (type === "registration") {
      setType("login");
    } else {
      setType("registration");
    }
  };

  return (
    <div className="login_block">
      <div className="section_header">{type === "login" ? "Авторизация" : "Регистрация"}</div>
      {type === "registration" && (
        <div className="center mb-10">
          Почта нужна создания личного кабинета, где будет регистрироваться прогресс обучения.
        </div>
      )}
      <input onChange={(e) => setEmail(e.target.value)} autoFocus placeholder="электронная почта" />
      {type === "login" && <input onChange={(e) => setPassword(e.target.value)} autoFocus placeholder="пароль" />}
      {error.length > 0 && <div className="error center">{error}</div>}
      {
        <div className="policy_link">
          Регистрируясь, вы подтверждаете, что принимаете{" "}
          <a target="_blank" href="https://bestquestion.ru/policy">
            Пользовательское соглашение
          </a>
        </div>
      }
      <button className="btn-main button mb-10" onClick={clickHandler}>
        {type === "login" ? "Войти" : "Создать аккаунт"}
      </button>
      <div className="center link" onClick={changeType}>
        {type === "login" ? "Нет аккаунта" : "Есть аккаунт"}
      </div>
    </div>
  );
};

import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { API } from "../../config/api";
import { addInfoToLocalStorage } from "../../utils/localStorage";

export const PaymentSuccess = () => {
  const history = useNavigate();

  const getNewToken = async () => {
    const data = await API.client.getToken();
    addInfoToLocalStorage(data);
  };

  useEffect(() => {
    getNewToken();
  }, []);

  return (
    <div className="payment_block">
      <p>Платеж получен!</p>
      <p>Хорошей вам практики!</p>
      <button className="button" onClick={() => history("/levels")}>
        Перейти к обучению
      </button>
    </div>
  );
};

import { FC, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import { API } from "../config/api";
import practice from "../assets/writing.png";
import theory from "../assets/book.png";
import ReactMarkdown from "react-markdown";

export const Level = () => {
  const [openTheory, setOpenTeory] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams() as { id: string };
  const queryClient = useQueryClient();
  const history = useNavigate();

  const { data, isError, isLoading } = useQuery(id, () => API.client.getLevel(id));

  const clickHandler = async (type: "next" | "previous") => {
    if (data) {
      if (type === "next" && data.levels.next) {
        if (data.index === 1) {
          await API.client.doneLevel({ level_id: data.id });
        }
        history("/levels/" + data.levels.next);
      } else if (type === "previous" && data.levels.previous) {
        history("/levels/" + data.levels.previous);
      }
    }
  };

  useEffect(() => {
    if (data && !data.paid) {
      setOpenTeory(true);
    }
    setLoaded(true);
  }, [data]);

  if (!data || !loaded) return null;

  return (
    <div>
      <div className="header">
        <span className="menu_item" onClick={() => history("/levels")}>
          Все темы
        </span>{" "}
        / <span>{data.name}</span>
      </div>
      <div className="wrapper">
        <div className="card card-white">
          <div className="mb-20">
            <img className="icon" src={theory} />
            {/* <div className="hint">теория</div> */}
          </div>
          <div>
            <div className="mb-10">
              {data.theories ? (
                openTheory ? (
                  <div className="text">
                    <ReactMarkdown
                      children={data.description}
                      components={{
                        img: ({ node, ...props }) => <img style={{ maxWidth: "100%" }} {...props} />,
                        code: ({ node, ...props }) => (
                          <code
                            style={{ backgroundColor: "lightgoldenrodyellow", padding: "1px 3px", borderRadius: "1px" }}
                            {...props}
                          />
                        ),
                        h4: ({ node, ...props }) => (
                          <h4 style={{ marginBlockStart: "2em", marginBlockEnd: "0em" }} {...props} />
                        ),
                      }}
                    />
                    {/* {data.theories.map((item: any, index: any) => (
                      <div key={index} className="text_block">
                        <div className="bold mb-10">{item.heading}</div>
                        <div>{item.text}</div>
                      </div>
                    ))} */}
                  </div>
                ) : (
                  <div>{data.theories[0]?.text}</div>
                )
              ) : null}
            </div>
            {data.paid && (
              <div>
                {openTheory ? (
                  <button className="button" onClick={() => setOpenTeory(false)}>
                    Понятно
                  </button>
                ) : (
                  <button className="button" onClick={() => setOpenTeory(true)}>
                    Читать
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        {data.exercises.length > 0 && (
          <div className="card card-white">
            <div className="mb-20">
              <img className="icon" src={practice} />
              {/* <div className="hint">практика</div> */}
            </div>
            <div>
              <div>
                <div className="mb-10">
                  {data.exercises.map((item, index: number) => (
                    <div key={index}>
                      <span className="link" onClick={() => history("/exercise/" + item.id)}>{`Упражнение ${
                        index + 1
                      }`}</span>
                      <span className="level_count">{` (${item.answers_count}/${item.tasks_count} задач)`}</span>
                    </div>
                  ))}
                </div>
                {/* <button className="button">Начать</button> */}
              </div>
            </div>
          </div>
        )}
        <div className="step_buttons_block">
          <div onClick={() => clickHandler("previous")}>Предыдущий</div>
          <div onClick={() => clickHandler("next")}>Следующий</div>
        </div>
      </div>
    </div>
  );
};

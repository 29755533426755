import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import logo from "../../assets/log.png";
import question from "../../assets/question.png";
import black from "../../assets/black.svg";
import { useNavigate } from "react-router";
import "./index.css";
import { useEffect } from "react";

export const Landing = () => {
  const history = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      history("levels");
    }
  }, []);
  return (
    <>
      {/* <div>
        <img src={logo} style={{ width: "80px" }} />
      </div> */}
      <div>
        <div className="main_block">
          <div className="main_block_flex">
            <div>
              <h1>
                Преобразите свою жизнь с помощью <span style={{ color: "#f71f5c" }}>исскуства задавать вопросы</span>
              </h1>
              <p className="mb-40">
                <span style={{ textDecoration: "underline" }}>Уникальный онлайн-тренажер</span>
                <br /> научит вас общаться
                <br /> с кем угодно и о чем угодно.
              </p>
            </div>
            <img src={question} />
          </div>
          <div className="cards">
            <div className="card_block">Выполните более 300 заданий</div>
            <div className="card_block">Зададите более 1000 вопросов</div>
            <div className="card_block">Узнаете более 10 типов вопросов</div>
          </div>
        </div>
        <section>
          <div className="section_header">Зачем нужен тренажер</div>

          <div className="cards_two">
            <div className="card_block card-white">
              <div className="bold mb-10">Хотите сделать общение более глубоким</div>
              <div>
                Чувствуете, что поверхностные разговоры не приносят удовлетворения? Хотите задавать глубокие и
                проницательные вопросы, которые раскроют истинные мысли и чувства собеседника?
              </div>
              <img src={black} className="card_img" />
            </div>
            <div className="card_block card-white">
              <div className="bold mb-10">Стремитесь к успешной карьере</div>
              <div>
                Хотите научиться задавать эффективные вопросы при общении с руководством и коллегами? Как формулировать
                вопросы, способствующие продуктивному обмену идей и совместному решению проблем?
              </div>
              <img src={black} className="card_img" />
            </div>
            <div className="card_block card-white">
              <div className="bold mb-10">Ищете способы улучшить отношения</div>
              <div>
                Желаете научиться коммуникации, способствующей разрешению конфликтов, улучшению взаимопонимания и
                созданию эмоциональной поддержки в романтических или супружеских отношениях?
              </div>
              <img src={black} className="card_img" />
            </div>
            <div className="card_block card-white">
              <div className="bold mb-10">Хотите легко разговаривать с разными людьми</div>
              <div>
                Ощущаете дискомфорт при общении с незнакомцами и хотелось бы научиться уверенно задавать вопросы,
                устанавливая контакт и создавая приятную атмосферу общения?
              </div>
              <img src={black} className="card_img" />
            </div>
          </div>
        </section>
        <section>
          <div className="section_header">Это - не очередной курс</div>
          <div className="line " />
          <p>Обычно, после прохождения курсов навыки не закрепляются в жизни.</p>
          <p>
            Причина в том, что не хватает систематической практики. Без этого получить новые навыки невозможно. Чем чаще
            ты тренируешь свои навыки, тем лучше они закрепляются.
          </p>
          <p>
            С помощью тренажера вы доведёте навык задавания интересных вопросов до автоматизма. Вы больше не будете
            думать "Что бы спросить?".
          </p>
        </section>
        <section>
          <div className="block color-2 center">
            <div>
              <div className="text_heading mb-20">Стоимость</div>
              <div className="card_block pointer" onClick={() => history("login")}>
                Вся теория - <span style={{ color: "#f71f5c" }}>БЕСПЛАТНО</span>
              </div>
              <p>Изучение всей теории покажет, насколько вам интересно приобретение этих навыков.</p>
              <div className="line " />
              <p>Практика - 2 900 р.</p>
              <div className="card_block pointer" onClick={() => history("login")}>
                НАЧАТЬ БЕСПЛАТНО
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="section_header">Частые вопросы</div>
          <div className="line" />
          <div className="mt-20">
            <div className="mb-20">
              <div className="bold">Какие темы будут?</div>
              <div>После регистрации вам будет доступен полный план обучения и вся теория.</div>
            </div>
            <div className="mb-20">
              <div className="bold">Сколько времени проходит обучение?</div>
              <div>
                Вы можете проходить в удобном для себя темпе. Рекомендуется не проходить больше одной главы за день,
                чтобы мозг успел усвоить информацию. Большинство глав потребуют больше часа времени на прохождение.
              </div>
            </div>
            <div className="mb-20">
              <div className="bold">Будут ли вебинары?</div>
              <div>
                Нет, это обучение не предполагает проведение вебинаров, так как вебинары - это только теория. Здесь вы
                будете заниматься только практикой.
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="block color-2 center footer">
            <div>BestQuestion.ru</div>
            <div>best.question@yandex.ru</div>
            <a href="https://t.me/bestquestion_bot" style={{ color: "white" }}>
              @bestquestion_bot
            </a>
            <div>
              <a className="policy_link_main" target="_blank" href="https://bestquestion.ru/policy">
                Политка конфидициальности
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

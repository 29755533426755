import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { API } from "../config/api";
import { ReactComponent as Icon } from "../assets/back.svg";

export const Profile = () => {
  const { data, isError, isLoading, isLoadingError } = useQuery("profile", API.client.getProfile);

  const history = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    history("/");
  };

  if (!data) return null;
  return (
    <div>
      <div className="header justify mb-20">
        <span>Профиль</span>
        <Icon className="icon-small" onClick={() => history("/levels")} />
      </div>
      <div className="mb-10">Почта: {data.email}</div>
      <div className="mb-10">Оплата: {data.paid ? "Оплачено" : "Не оплачено"}</div>
      <div className="mb-10">Очки: {data.points}</div>
      <button onClick={logout}>
        Разлогиниться
      </button>
    </div>
  );
};

import { useQuery } from "react-query";
import { API } from "../config/api";
import { useNavigate } from "react-router";
import { ReactComponent as Account } from "../assets/account.svg";

export const Levels = () => {
  const history = useNavigate();

  const { data, isError, isLoading, isLoadingError } = useQuery("levels", API.client.getLevels);
  const { data: points } = useQuery("points", API.client.getPoints);

  if (!data || !points) return null;

  return (
    <div>
      <div className="header justify">
        <span>Все темы</span>
        <div className="header_block">
          <span className="points">{points.count}</span>
          <Account className="icon-small" onClick={() => history("/profile")} />
        </div>
      </div>
      <div className="wrapper">
        {data?.map((level, index) => (
          <div
            key={level.id}
            className={level.type ? `level level-${level.type}` : "level"}
            onClick={() => history(level.id)}
          >
            <div>
              {index + 1}. {level.name}
            </div>
            <div>{level.type === "done" ? <span className="level_icon"> &#10004;</span> : null}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

import logo from "./logo.svg";
import "./App.css";
import { Levels } from "./pages/Levels";
import { Route, Routes } from "react-router";
import { Level } from "./pages/Level";
import { Exercise } from "./pages/Exercise";
import { Landing } from "./pages/Outer/Landing";
import { Login } from "./pages/Outer/Login";
import { Profile } from "./pages/Profile";
import PolicyPage from "./pages/Outer/PolicyPage";
import { Payment } from "./pages/components/Payment";
import { PaymentSuccess } from "./pages/components/PaymentSuccess";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/levels" element={<Levels />} />
        <Route path="/levels/:id" element={<Level />} />
        <Route path="/exercise/:id" element={<Exercise />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/policy" element={<PolicyPage />} />
        <Route path="/payment/:user_id" element={<Payment />} />
        <Route path="/success" element={<PaymentSuccess />} />
      </Routes>
    </div>
  );
}

export default App;

import Axios, { AxiosError } from "axios";

export const axios = Axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "https://bestquestion.ru/api" : "https://bestquestion.ru/api",
});

axios.interceptors.request.use(
  (config) => {
    const URL = config.url;
    if (URL?.includes("user/auth")) {
      return config;
    }
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      return window.location.assign("/login");
    }
  }
);

export interface ILevel {
  id: string;
  name: string;
  description: string;
  theories: any[];
  type: string;
  index: number;
  paid: boolean;
  exercises: IExercise[];
  levels: {
    next: string;
    previous: string;
  };
}

export interface ITask {
  id: number;
  text: string;
  answer: string;
  answers: IAnswer[];
}

export interface IExercise {
  id: string;
  text: string;
  description: string;
  task: ITask;
  minimum: number;
  index: number;
  tasks_count: number;
  answers_count: number;
  level: ILevel;
}

export interface IExerciseFullResult extends IExercise {
  status: "task" | "result";
  next_type: string;
  next_item: string;
  level_id: string;
  paid: boolean;
  tasks: {
    done: number;
    all: number;
  };
}

export interface IAnswer {
  id: string;
  text: string;
}

export interface IUser {
  id: string;
  email: string;
  paid: boolean;
  points: number;
}

export interface IPoints {
  count: number;
}

export const API = {
  client: {
    async getLevels() {
      const { data } = await axios.get<ILevel[]>("levels");
      return data;
      try {
        const { data } = await axios.get<ILevel[]>("levels");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getLevel(id: any) {
      try {
        const { data } = await axios.get<ILevel>("levels/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getTheory(level_id: any) {
      try {
        const { data } = await axios.get<any>("theory/" + level_id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getOnlyTheory(level_id: any) {
      try {
        const { data } = await axios.get<any>("theory/only/" + level_id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getExercise(exercise_id: any) {
      try {
        const { data } = await axios.get<IExerciseFullResult>("exercises/" + exercise_id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addAnswer(dto: { text: string; exercise_id: string; task_id: number }) {
      try {
        const { data } = await axios.post("answers", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async doneTask(dto: { task_id: number; exercise_id: string }) {
      try {
        const { data } = await axios.post("done", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async doneLevel(dto: { level_id: string }) {
      try {
        const { data } = await axios.post("done", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async login(dto: { email: string }) {
      try {
        const { data } = await axios.post("users/auth", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async registration(dto: { email: string; password: string }) {
      try {
        const { data } = await axios.post("users/auth", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getProfile() {
      try {
        const { data } = await axios.get<IUser>("users");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getPoints() {
      try {
        const { data } = await axios.get<IPoints>("answers");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getToken() {
      try {
        const { data } = await axios.get("users/token");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
};

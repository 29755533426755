import { FC, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import TextareaAutosize from "react-textarea-autosize";
import { API } from "../config/api";
import { ExerciseResult } from "./components/ExerciseResult";
import theoryIcon from "../assets/book.png";
import theoryIcon2 from "../assets/book2.png";
import writingIcon from "../assets/writing.png";
import writingIcon2 from "../assets/writing2.png";
import { PaymentBlock } from "./components/PaymentBlock";

export const Exercise = () => {
  const [text, setText] = useState("");
  const [isDesc, setIsDesc] = useState(false);
  const [isTheory, setIsTheory] = useState(false);
  const [theory, setTheory] = useState({ open: false, data: [] });
  const [isShowAnswers, setIsShowAnswers] = useState(false);
  const { id } = useParams() as { id: string };
  const queryClient = useQueryClient();
  const history = useNavigate();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { data, isError, isLoading, refetch } = useQuery(id, () => API.client.getExercise(id));

  const mutation = useMutation({
    mutationFn: API.client.addAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(id);
      setText("");
    },
  });

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (text.length > 1 && data) {
        mutation.mutate({ text, task_id: data.task.id, exercise_id: data.id });
      }
    }
  };

  const submitAnswer = () => {
    API.client.doneTask({ task_id: data!.task.id, exercise_id: id });
    // setIsShowAnswers(true);
    if (isDesc) {
      setIsDesc(false);
    }
    refetch();
  };

  const nextTask = () => {
    refetch();
    setIsShowAnswers(false);
  };

  const getTheory = async () => {
    if (theory.data.length === 0) {
      const res = await API.client.getOnlyTheory(id);
      setTheory({ open: true, data: res });
    } else {
      setTheory({ ...theory, open: !theory.open });
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [data]);

  useEffect(() => {
    // if (data?.paid && data?.tasks.done === 0) {
    //   setIsDesc(true);
    // }
    const isFirstQuestion = data && data.tasks?.done === 0 && data.task?.answers.length === 0;
    if (isFirstQuestion) {
      setIsDesc(true);
    }
  }, [data]);

  if (!data) return null;
  if (data.paid === false) return <PaymentBlock />;
  if (isLoading) return null;
  if (isError) return null;

  return (
    <div>
      <div className="header">
        <div className="mb-10">
          <span className="menu_item" onClick={() => history("/levels")}>
            Все темы
          </span>{" "}
          /{" "}
          <span className="menu_item" onClick={() => history("/levels/" + data.level_id)}>
            {data.level.name}
          </span>
        </div>
        <div className="flex">
          <span>{`Упражнение №${data.index}`}</span>
          <span>{` (${data.tasks.done} / ${data.tasks.all})`}</span>
          <img className="icon-small" src={theory.open ? theoryIcon2 : theoryIcon} onClick={getTheory} />
          <img className="icon-small" src={isDesc ? writingIcon2 : writingIcon} onClick={() => setIsDesc(!isDesc)} />
        </div>
      </div>
      <div className="blank"></div>

      {isDesc && (
        <div className="card text">
          <div className="mb-10">{data.description}</div>
          <button className="button" onClick={() => setIsDesc(false)}>
            Понятно
          </button>
        </div>
      )}

      {theory.open && (
        <div className="card text">
          {theory.data.map((item: any) => (
            <div className="text_block">
              <div className="bold mb-10">{item.heading}</div>
              <div>{item.text}</div>
            </div>
          ))}
          <button className="button" onClick={() => setTheory({ ...theory, open: false })}>
            Понятно
          </button>
        </div>
      )}

      {data.status === "task" ? (
        <>
          <div className="exercise_question_block">
            <h2>{data.text}</h2>
          </div>
          <div className="task mb-20">{data.task.text}</div>
          <TextareaAutosize
            className="textarea mb-40"
            value={text}
            onKeyDown={handleKeyPress}
            onChange={(e) => setText(e.target.value)}
            ref={textAreaRef}
          />
          {data.task.answers.length > 0 && <div>Твои ответы:</div>}
          <ol>
            {data.task.answers.map((answer: any) => (
              <li key={answer.id}>{answer.text}</li>
            ))}
          </ol>
          {/* {isShowAnswers && (
              <div>
                <div>Возможные ответы:</div>
                <ul>
                  {data.task.answer
                    .split(";")
                    .filter((item) => item.length > 0)
                    .map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                </ul>
              </div>
            )} */}
          {data.task.answers.length >= data.minimum ? (
            isShowAnswers ? (
              <button className="button" onClick={nextTask}>
                Следующий вопрос
              </button>
            ) : (
              <button className="button" onClick={submitAnswer}>
                Готово
              </button>
            )
          ) : null}
        </>
      ) : (
        <ExerciseResult type={data.status} next_item={data.next_item} next_type={data.next_type} />
      )}
    </div>
  );
};

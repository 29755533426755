import { Helmet } from "react-helmet";
import { useParams } from "react-router";

export const Payment = () => {
  const { user_id } = useParams();

  return (
    <div className="payment_block">
      <div id="payment-form"></div>
      <Helmet>
        <script type="text/javascript">{`
                const options = {
        account: 87281672,
        amount: 2900,
        transactionId: '${user_id}',
        subscriberId: '${user_id}',
        customParams: {
          user_id: '${user_id}'
        }
    };

    const assistant = new Assistant.Builder();

    assistant.setOnSuccessCallback(function(operationId, transactionId) {
        location.replace("https://bestquestion.ru/success");
    });

    assistant.setOnFailCallback(function(operationId, transactionId) {
    });

    assistant.setOnInProgressCallback(function(operationId, transactionId) {
    });
          console.log('options', options)
    assistant.build(options, 'payment-form');
            `}</script>
      </Helmet>
      <div className="center mt-20">
        Все вопросы по сервису пишите на почту best.question@yandex.ru или в телеграмм @bestquestion_bot
      </div>
    </div>
  );
};
